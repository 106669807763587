import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";


import Slider from "react-slick";

import { sliderSettings } from '../Home/constants';
import GoogleReviewLogo from "../../Assets/images/google-review-logo.png";


const additionalSliderSettings = {
    initialSlide: 0,
    autoplay: true,
};
const title = 'AGM Renovations Reviews';
const text  = '';

export class Reviews extends Component {

  state = {

  };


  render() {

    return (
      <div>

         <Helmet>
           <title>{this.props.title}</title>
           <meta name="description" content={this.props.description} />
           {this.props.keywords && <meta name="keywords" content={this.props.keywords} />}
           <meta name="robots" content="index"/>
         </Helmet>

        <section className="video-intro our-works">
            <div className="container">
            <div className="cnt">
                <h2 style={{ padding: '0'}}>{title}</h2>
                <h4>{text}</h4>
            </div>
            </div>
        </section>



        <section className="about-section-2 reviews" style={{ paddingBottom: "0px", paddingTop: "40px" }} >
            <div
                style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingBottom: "0px",
                maxWidth: "980px",
                margin: "0 auto",
                }}
            >
                <div id="agm-difference-top" style={{ padding: "0px", marginTop: "0" }}>
                        {this.props.children}
                </div>

            </div>
        </section>

        <section className="home-testimonials">
            <div className="container">
                <div className="sub-title">Reviews</div>
                <h2>{'What they say about us'}</h2>
                <img className="google-img" src={GoogleReviewLogo} alt="Google Review Logo" />
                <div className="testimonial-slider">
                <Slider {...sliderSettings} {...additionalSliderSettings}>
                    {this.props.loopItems.map((item, index) => (
                    <div className="box" data-number={`0${index + 1}`} key={index}>
                        <p>{item.text}</p>
                        <div className='testimonials-user'>
                        {
                            item.photo && <img className='testimonials-photo' src={item.photo} alt="Customer who've left review" />
                        }
                        <div className='testimonials-info'>
                            <div className="name">{item.name}</div>
                            <div className="rating">
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            </div>
                        </div>
                        </div>
                    </div>
                    ))}
                </Slider>
                </div>
                </div>
        </section>

      </div>
    )
  }
}

export default withRouter(Reviews);