import Axios from "axios";

export class FilesFetcher {

	static OTHER_SERVISES = "OTHER_SERVISES";
	static WARRANTY_ENDPOINTS = "WARRANTY_ENDPOINTS";
	static WARRANTY_SERVISES = "WARRANTY_SERVISES";
	static SOCIAL_LINKS = "SOCIAL_LINKS";
	static LISTING_IMAGES = "LISTING_IMAGES";
	static ARTICLES = "ARTICLES";
	static PROMO_OFFER = "PROMO_OFFER";

	constructor(client) {
		this.client = client;
		this.cache = new Map();
	}

	async fetchPromoOffer() {

		const cached = this.cache.get(FilesFetcher.PROMO_OFFER);

		if (cached) {
			return cached;
		}

		const response = await this.client.get("https://agm-landing.s3.us-east-2.amazonaws.com/websites/promoOffers.json");

		const { data: servicesJSON } = response;

		this.cache.set(FilesFetcher.PROMO_OFFER, servicesJSON);

		return servicesJSON;
	}

	async fetchOtherServices() {

		const cached = this.cache.get(FilesFetcher.OTHER_SERVISES);

		if (cached) {
			return cached;
		}

		const response = await this.client.get("https://agm-landing.s3.us-east-2.amazonaws.com/websites/otherServices.json");

		const { data: servicesJSON } = response;

		this.cache.set(FilesFetcher.OTHER_SERVISES, servicesJSON);

		return servicesJSON;
	}

	async fetchWarrantyEndpoints() {

		const cached = this.cache.get(FilesFetcher.WARRANTY_ENDPOINTS);
	
		if (cached) {
			return cached;
		}

		const response = await this.client.get("https://agm-landing.s3.us-east-2.amazonaws.com/websites/warranty_sub_endpoints.json");

		const { data: servicesJSON } = response;

		this.cache.set(FilesFetcher.WARRANTY_ENDPOINTS, servicesJSON);

		return servicesJSON;
	}

	async fetchWarrantyServices() {

		const cached = this.cache.get(FilesFetcher.WARRANTY_SERVISES);

		if (cached) {
			return cached;
		}

		const response = await this.client.get("https://agm-landing.s3.us-east-2.amazonaws.com/websites/warrantyServices.json");

		const { data: servicesJSON } = response;

		this.cache.set(FilesFetcher.WARRANTY_SERVISES, servicesJSON);

		return servicesJSON;
	}

	async fetchSociaLinks() {

		const cached = this.cache.get(FilesFetcher.SOCIAL_LINKS);

		if (cached) {
			return cached;
		}

		const response = await this.client.get("https://agm-landing.s3.us-east-2.amazonaws.com/websites/agm_social_links.json");

		const { data: social_links } = response;

		this.cache.set(FilesFetcher.SOCIAL_LINKS, social_links);

		return social_links;
	}

	async fetchListingImages() {

		const cached = this.cache.get(FilesFetcher.LISTING_IMAGES);

		if (cached) {
			return cached;
		}

		const response = await this.client.get("https://agm-landing.s3.us-east-2.amazonaws.com/websites/agm_listing.json");

		const { data: listingImages } = response;
		this.cache.set(FilesFetcher.LISTING_IMAGES, listingImages);

		return listingImages;
	}


	async fetchArticles() {

		const cached = this.cache.get(FilesFetcher.ARTICLES);

		if (cached) {
			return cached;
		}

		const response = await this.client.get("https://agm-landing.s3.us-east-2.amazonaws.com/websites/articles.json");

		const { data: articles } = response;

		this.cache.set(FilesFetcher.ARTICLES, articles);

		return articles;
	}
	
}

export default new FilesFetcher(Axios);