import React, { Component } from 'react';

// import { Link } from "react-router-dom";
// import AWS from 'aws-sdk';


class Finish extends Component {
  static propTypes = {
  };

  state = {
  };

  componentDidMount = () => {
  }

  render() {
    const { title, description, image, selected } = this.props;
    return (
      <div className={`single-finish ${selected ? 'selected' : null}`}>
        <div className="image-holder">
          {selected ? <i className="far fa-check-square" /> : null}
          <img src={'https://bathroom-reno-finishes.s3.us-east-2.amazonaws.com/' + image} alt={title} />
        </div>
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
    );
  }
}

export default Finish;