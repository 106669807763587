import React from 'react';
import { Helmet } from "react-helmet";

import {
  TopSection,
  WorkSteps,
  CreatorFeatures,
  Enjoy,
} from '../../Containers/HowItWorks';

import bannerLaptop from "../../Assets/images/kitchens/howitworks/laptop1.png";
import HiwStepOne from "../../Assets/images/hiw_step_1.png";
import HiwStepTwo from "../../Assets/images/kitchens/howitworks/step2.jpeg";
import HiwStepThree from "../../Assets/images/kitchens/howitworks/step3.jpeg";
import HiwStepFour from "../../Assets/images/hiw_step_4.png";
import HiwStepFive from "../../Assets/images/hiw_step_5.png";
import HiwStepSix from "../../Assets/images/hiw_step_6.jpg";
import HiwStepSeven from "../../Assets/images/kitchens/howitworks/step7.jpeg";
import HiwStepEight from "../../Assets/images/kitchens/howitworks/step8.jpeg";
import CreatorImageOne from "../../Assets/images/kitchens/howitworks/laptop1.png";
import CreatorImageTwo from "../../Assets/images/kitchens/howitworks/drawers.jpg";
import CreatorImageThree from "../../Assets/images/kitchens/howitworks/counter-top.png";
import CreatorImageFour from "../../Assets/images/kitchens/howitworks/sinks.png";
import CreatorImageFive from "../../Assets/images/kitchens/howitworks/kt.jpg";
import CreatorImageSix from "../../Assets/images/creator_image_2.png";
import enjoyImg from "../../Assets/images/kitchens/howitworks/how-it-works-bg.jpg";

export const HowItWorksPage = () => {

  const topSectionProps = {
    className: 'kitchens__how-works',
    bannerImgProps: {
      alt: 'laptop with kitchen on the screen',
      src: bannerLaptop,
    },
  };

  const workStepsItems = [
    {
      title: 'Start By Giving Us Info About Your Existing Kitchen',
      description: 'After answering a few quick questions – we’ll gain an understanding about your existing kitchen.',
      imgProps: {
        src: HiwStepOne,
      }
    },
    {
      title: 'Browse Finishings',
      description: 'View our luxury finishing’s and choose your ideal selections on all fixtures and features.',
      imgProps: {
        src: HiwStepTwo,
      }
    },
    {
      title: 'Create Your Dream Kitchen',
      description: 'Using our 3D creator you can mix and match elements for your kitchen including the layout.',
      imgProps: {
        src: HiwStepThree,
      }
    },
    {
      title: 'Get An Instant Quote',
      description: 'After finalizing your kitchen design you will be provided a quote instantly.',
      imgProps: {
        src: HiwStepFour,
      }
    },
    {
      title: 'Schedule A Start Date',
      description: 'Choose a date that works best for you for our renovation team to start.',
      imgProps: {
        src: HiwStepFive,
      }
    },
    {
      title: 'Materials At Your Doorstep',
      description: 'We bring all materials to your home for you to see and touch all fixtures and finishes. At this time, you can also make any last-minute changes before we commence construction.',
      imgProps: {
        src: HiwStepSix,
      }
    },
    {
      title: 'Construction Begins',
      description: 'Our team of highly crafted tradespeople will build your new kitchen and will be completed in time and on budget. That is our guarantee.',
      imgProps: {
        src: HiwStepSeven,
      }
    },
    {
      title: 'Enjoy Your New Kitchen',
      description: 'And voila – your new kitchen is now built designed 100% according to your specifications.',
      imgProps: {
        src: HiwStepEight,
      }
    },
  ]

  const creatorFeaturesItems = [
    {
      text: (
        <>
          <strong>3D Design</strong><br/> Upload your kitchen dimensions
                                          and see your space in 3D. From here, it’s easy to choose
                                          fixtures, flooring and finishes, so you can design your dream
                                          kitchen instantly.
        </>
      ),
      imgProps: {
        src: CreatorImageOne,
      }
    },
    {
      text: (
        <>
          <strong>Cabinets</strong><br/> View our wide selection of cabinet door styles, and choose from variety of
                                         kitchen features to refine your kitchen design.
        </>
      ),
      imgProps: {
        src: CreatorImageTwo,
      },
    },
    {
      text: (
        <>
          <strong>Countertops</strong><br/> Choose from a variety of countertop options including granite, marble and
                                            quartz. Our wide selection of patterns and colors will allow you to
                                            customize the kitchen of your dream.
        </>
      ),
      imgProps: {
        src: CreatorImageThree,
      },
    },
    {
      text: (
        <>
          <strong>Sinks</strong><br/> The kitchen sink is one of the most-used items in your kitchen. Our vast selection
                                      of popular kitchen sink styles will make it easy to pick one you love!
        </>
      ),
      imgProps: {
        src: CreatorImageFour,
      },
    },
    {
      text: (
        <>
          <strong>Faucets</strong><br/> Looking for a modern, traditional or transitional faucet style? We have options!
                                        Mix and match different faucet styles with your sink to create your ideal
                                        kitchen.
        </>
      ),
      imgProps: {
        src: CreatorImageFive,
      },
    },
    {
      text: (
        <>
          <strong>Luxury Tiles</strong><br/> Scroll through our collection of ready-to-order tiles and flooring. Can’t
                                             decide? With our 3D Creator, you can swap in different tile options and see
                                             your design instantly updated.
        </>
      ),
      imgProps: {
        src: CreatorImageSix,
      },
    },
  ]

  const enjoyProps = {
    className: 'kitchen__enjoy',
    title: <><span>Indulge In Your</span> New Kitchen</>,
    imgProps: {
      src: enjoyImg,
      alt: 'kitchen',
    }
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="index"/>
        <meta name="description" content={`With just a few questions of what your space looks like now and what your dream (Insert_Reno_Type) would look like, we can begin guiding you through the available luxury finishes to design your dream (Insert_Reno_Type), get quoted instantly and schedule a start date today`} />
        <meta name="keywords" content={`AGM Process, AGM How It Works, AGM Design, AGM Materials, AGM Finishes, AGM Start Date, AGM Quote`} />
        <title>AGM Kitchens Renovations  |  AGM Renovations How It Works</title>
      </Helmet>

      <TopSection {...topSectionProps}  />
      <WorkSteps items={workStepsItems}/>
      <CreatorFeatures items={creatorFeaturesItems}/>
      <Enjoy {...enjoyProps} />
    </>
  )
}