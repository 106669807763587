import React, { useState, useEffect } from 'react'

function PrivacyPolicy() {
  return (
    <div className="container">
      <h1>Privacy Policy</h1>
      <br/><br/><br/>
      <p><strong>Introduction</strong></p>

      <p>AGM Home Renovations Inc. (&ldquo;AGM&rdquo; also referred to as &ldquo;we&rdquo;, &ldquo;us&rdquo;, and/or &ldquo;our&rdquo;) is an independent corporate entity which has a
        practice of protecting the privacy and security of customer, supplier and employee records. We are committed to meeting our obligations under Canadian data privacy laws, including the <i>Personal Information 
        Protection and Electronic Documents Act</i>, S.C. 2000, c. 5, and applicable provincial laws. We adhere to the privacy principles set out below, which govern the way we collect, use, store and disclose personal
        information that is obtained in the course of development, sales, promotion and distribution of our products and services or in the course of employment. The below is referred to as AGM&rsquo;s privacy policy (the &ldquo;Privacy Policy&rdquo;).</p>

      <p>&ldquo;Personal information&rdquo;, as used in this Privacy Policy, means any information provided to or collected by AGM about an identifiable person, including employee records, and customer and
        supplier information, but does not include the name, title, business address, business telephone number, business fax number or business e-mail address of an employee of an organization. This Privacy
        Policy applies regardless of how Personal Information is recorded (for example, electronically, or on paper). This Privacy Policy does not cover any information about more than one individual where the identity
        of the individuals is not known and cannot be inferred from the information (&ldquo;Aggregated Information&rdquo;). AGM retains the right to use Aggregated Information in any way that it reasonably determines is
        appropriate. This Privacy Policy also does not apply to information about companies or other legal entities.</p>

      <p><strong>Privacy Policy for the Protection of Personal Information:</strong></p>

      <p>We will collect, use, store and disclose personal information in accordance with the following privacy principles:</p>

      <p><strong>1 Accountability</strong></p>

      <p>The overall responsibility for ensuring our compliance with data privacy laws and this Privacy Policy rests with our Privacy Officer, although other individuals within AGM have responsibility for the day-to-day
        collection and processing of personal information and may be delegated to act on behalf of the Privacy Officer.<br />

        We are responsible for personal information in our possession or custody, including personal information that we may transfer to third parties for processing. We will require our service providers to agree to
        contractual requirements that are consistent with our privacy and security policies. We will require that our service providers are prohibited from using personal information, except for the specific purpose for which we supply it to them.
      </p>

      <p><strong>2 Identifying Information Collected and Purposes</strong></p>

      <p>Either before or at the time of collection, we will identify the purposes for which we plan to use the personal information. Depending upon the way in which the personal information is collected, this
        can be done orally or in writing. AGM may use the information we collect for the following purposes:</p>

      <p>(a) to ship products or provide services to our customers;</p>
      <p>(b) to provide customers access to AGM&rsquo;s products or services;</p>
      <p>(c) to respond to customer inquiries about accounts and other services;</p>
      <p>(d) to understand our customers and prospective customers&rsquo; needs and to offer products and services to meet those needs;</p>
      <p>(e) to conduct credit checks on customers or prospective customers; and</p>
      <p>(f) to meet legal requirements.</p>

      <p>Unless required by law, we will not use personal information for a new purpose without the knowledge and consent of the individual to whom the information relates. We hold this information in strict confidence
        and do not reveal it to anyone unless expressly or implicitly authorized by the person or organization concerned or where permitted or required by applicable law.</p>

      <p>In the course of our relationship with you, AGM will need to collect, use, and sometimes disclose different types of Personal Information for various purposes associated with the services we provide as directed
        by you. Given the nature of our services, it is impractical to list all of types of Personal Information that may be collected, used or disclosed. However, the Personal Information we collect may include:</p>

      <p>• your full name;</p>
      <p>• contact information, such as your address, telephone number, e-mail address, or job titles;</p>
      <p>• information to help establish your identity;</p>
      <p>• whether you have subscribed to, or unsubscribed from, any our mailing lists, or accepted any of our invitations;</p>
      <p>• your payment information; and</p>
      <p>• any other Personal Information that you voluntarily choose to provide to us.</p>

      <p>In some cases, we collect Personal Information from third parties such as other organizations with whom we or you have dealings, such as government agencies, credit reporting agencies, recruitment agencies,
        information or service providers, and from publicly available records.</p>

      <p>• Information about your property for the purposes of preparing a quote.</p>

      <p><strong>3 Consent</strong></p>

      <p>Your provision of Personal Information to AGM means that you agree and consent to our collection, use and disclosure of your Personal Information under this Privacy Policy. If you do not agree with these terms,
        do not provide any Personal Information to us. However, while providing some Personal Information is optional, certain services can only be offered if you provide Personal Information to us and we may not be able
        to offer you certain services if you choose not provide us with any required Personal Information.<br />

        Consent may be given in different ways such as: a) expressly by signing a document, agreeing through electronic means or verbally; or b) implicitly by providing the Personal Information voluntarily.<br />

        Personal information will only be collected, used or disclosed with the consent of the individual, except in certain circumstances permitted or required by law. The way in which we seek consent may vary depending
        upon the sensitivity of the information. We will obtain consent in all cases where the personal information involved is considered sensitive, such as income or health information.<br />

        Typically, we will seek consent for the use or disclosure of personal information at the time of collection. However, additional consent will be sought after the personal information has been collected if it
        is required for a new purpose.<br />

        In certain circumstances, obtaining consent would be inappropriate. The federal <i>Personal Information Protection and Electronic Documents Act</i> and provincial privacy laws provide for exceptions where it is
        impossible or impractical to obtain consent. Please note that there are circumstances where the collection, use and/or disclosure of Personal Information may be justified or permitted without consent, or where
        AGM may or must disclose information without consent, in accordance with applicable law.<br />

        Further, and generally and unless otherwise revoked by the process set out below, you confirm that you agree and consent to receive texts or other forms of short message/messaging services (SMS) for promotional
        purposes or AGM&rsquo;s operations staff using thirty-party software. If you wish to opt-out and no longer be contacted by AGM&rsquo;s operations staff using third-party software or for the purposes of marketing
        or other forms of promotional marketing campaigns, please refer to our opt-out policies below.<br />

        You have the right to revoke your consent to the collection, use and disclosure of your Personal Information at any time. However, revocation of your consent may prevent us from providing services or products to
        you; in such circumstances, we will discuss with you the reason we need your Personal Information and why the revocation of your consent affects our ability to provide our services or products to you.
      </p>

      <p><strong>4 Limiting Collection, Storage, and Security</strong></p>

      <p>We will collect personal information by fair and lawful means and will limit the amount and type of personal information we collect to that which is necessary for our identified purposes.<br />

        Personal information will be retained for up to 3 years and will be stored in a locked storage facility on our company&rsquo;s premises. Only the Human Resources department and Privacy Officer have access
        to the locked storage facility in which the personal information records are being retained. After up to 3 years, the personal information will be destroyed in a secure manner and in accordance with applicable
        privacy legislation.<br />

        Upon an employee&rsquo;s resignation, retirement or termination of employment, the employee&rsquo;s personal information will be destroyed in a secure manner and in accordance with applicable privacy legislation.
      </p>

      <p><strong>5 Limiting Use, Disclosure and Retention</strong></p>

      <p>We will not use or disclose personal information for purposes other than those for which it was collected, except with the consent of the individual or as required by law.</p>

      <p><strong>6 Our Websites and Cookies</strong></p>

      <p>In general, you can use our websites without giving us any Personal Information. If you are one of our anonymous visitors, please bear in mind that we may still record certain analytical information and as further set out in this section.<br />

        AGM may use cookies, web beacons/pixel tags, log files and other technologies to collect certain information about visitors to our websites, and recipients of our newsletters, invitations and other communications.<br />

        When you visit our websites, we may collect information about your visit including your IP address, your geographic region (as determined from IP address), clickstream through our website, the date and time or your visit,
        information about your device and network such as the browser you use and its configuration, your connection speed, the pages you view or search for on our website, what links you click, what you download, our page
        response times, any download or other errors, the length of your visit, and whether the emails we send are opened.<br />

        We may use this information to improve the functionality of our website, to tailor or improve the content that we may send or show you, and for analytical purposes to understand how visitors interact with our website,
        messages and the information we provide.
      </p>

      <p><strong>7 Affiliates with Whom We Share Information</strong></p>

      <p>AGM may provide personal information to Welmond Inc. o/a AGM Basements and AGM Renovations which is bound by this same Privacy Policy. Personal Information may also be subject to transfer to another organization in the event of corporate
        transaction such as a merger, or change of ownership. This will occur only if the parties have entered into an agreement under which the collection, use and disclosure of the information is restricted to those
        purposes that relate to the business transaction, including determining whether or not to proceed with the business transaction, and is used by the parties to carry out and complete the business transaction.</p>

      <p><strong>8 Accuracy</strong></p>

      <p>AGM endeavours to ensure that any Personal Information provided and in its possession is as accurate, current and complete as necessary for the purposes for which we use that information.<br />

        You have a right to request access to your Personal Information and to request a correction to it if you believe it is inaccurate. In the event that you believe that your Personal Information is not accurate or you
        wish access to your Personal Information, you may make a request using the contact information provided below.<br />

        We endeavor to provide timely access to your Personal Information. However, we may require you to verify your identity to our satisfaction prior to doing so. Further, there may be circumstances where access cannot
        be granted. For example, we will not grant access where doing so would lead to the disclosure of Personal Information of another individual and that individual refuses to provide consent to the disclosure, or where
        the information is subject to privilege or other legal restrictions. In such cases you will be notified of the reason why it is not possible to grant access to your Personal Information.
      </p>

      <p><strong>9 Safeguards</strong></p>

      <p>We will protect personal information with safeguards appropriate to the level of sensitivity of the information. Our safeguards protect personal information against loss or theft, as well as unauthorized access,
        disclosure, copying, use or modification, regardless of the format in which the information is held. We will exercise care in the disposal or destruction of personal information to prevent unauthorized parties from
        gaining access to the information. Our methods of protection include physical measures (<i>e.g.</i>, locked file storage and restricted access to offices), organizational measures (<i>e.g.</i>, security clearances and limiting
        access on a need-to-know basis) and technological measures (<i>e.g.</i>, the use of passwords and encryption). We also require our outside service providers to provide a comparable level of protection to personal information that we may supply to them.
      </p>

      <p><strong>10 Individual Access</strong></p>

      <p>Upon written request, we will inform an individual of the existence, use and disclose of his/her personal information and give him/her reasonable access to that information. We may deny access for legally permissible
        reasons, such as situations where the information is prohibitively costly to provide, if it contains references to other individuals, or where it cannot be disclosed for legal, security or commercial proprietary reasons.
        We will advise the individual of any reason for denying an access request.<br />

        When an individual successfully demonstrates the inaccuracy or incomplete-ness of personal information held by us, we will correct or update the information as required.
      </p>

      <p><strong>11 Amendment of Our Practices and this Privacy Policy</strong></p>

      <p>This Privacy Policy is in effect as of December 5, 2022. AGM will from time-to-time review and revise its privacy practices and this Privacy Policy. In the event of any amendment, an appropriate notice will be posted on our website.<br />

        Your continued access to and/or use of our website or provision of Personal Information to us after any such changes constitutes your acceptance of, and agreement to this Privacy Policy, as revised. Please periodically review this
        Privacy Policy so that you know what Personal Information we collect, how we use it, and with whom we may share it.
      </p>

      <p><strong>12 Filing Inquiries and Complaints</strong></p>

      <p>If you ever If you ever have any questions, complaints or concerns regarding this Privacy Policy, any Personal Information you have submitted to AGM, or if you would like to:</p>

      <p>• access Personal Information that you have already provided so that you can correct or update it, or request that it be deleted, or</p>
      <p>• report any violation of this Privacy Policy</p>

      <p>please feel free to contact our Privacy Officer at the E-Mail below. We will investigate all written complaints and respond to all written inquiries. If we find a complaint to be justified, we will take appropriate measures to resolve it.<br />

        To file an opt-out request, request access to your information, report incorrect information or file a complaint, please write to our delegated Privacy Officer by E-Mail at privacyofficer@agmrenovations.com.
      </p>

    </div>
  )
}

export default PrivacyPolicy
