import React from 'react'
// import { Link } from "react-router-dom";
// import Logo from "../../Assets/images/bath_logo.svg";
// import VideoPreview from './VideoPreview';
// let region = '';

export const Intro = (props) => {

    const {
      title = 'Backed by decades of experience.',
      subtitle = 'Expert bathroom renovations since 1998.',
      text = 'In Canada, around 97.5% of renovation projects are either finished late or over budget. Only 2.5% of projects completed properly. As the oldest company in the industry, we are proudly part of this elite group, with a proven track record of finishing bathroom renovations on time and on budget.',
    } = props;

  return (
    <section className="home-intro">
      <div className="container">
        <div className="top-cnt">
          {/* <VideoPreview countryCode={props.countryCode} /> */}
          <h2>{title}</h2>
          <h3 className="quicksand">{subtitle}</h3>
        </div>
        <p>{text}</p>
        <a href={"/get-started"} className="blue-btn">
        Get Started
        </a>
      </div>
    </section>
  )
}
