import * as React from 'react';
import { useHistory } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
                
import HighlightOffIcon from '@mui/icons-material/Close';
import baneerOfferRef from '../Assets/images/Referral-Popup-BLUE.png';

const useStyles = makeStyles(theme => ({
    rootD: {
      ['& .MuiPaper-root']: {
        boxShadow: 'none',
        background: 'transparent',
        margin: '0',
        background: '#3330',
        boxShadow: 'none',
        transform: 'scale(0.85)',
      }
    },
    customIconCloseChat: {
      color: '#eee',
      opacity: '0.75',
      zIndex: '100',
      cursor: 'pointer', 
      borderRadius: '50%',
        cursor: "pointer",
        position: "absolute",
        right: '28px',
        top: "25px",
        width: '38px',
        height: '38px',
        color: '#ebebeb',

        '&:hover':{
          color: '#fff'
        }
    },
    banner: {
      width: "390px",
      padding: '30px 30px 20px 30px',
      borderRadius: '8px',
      maxWidth: '100%',
      background: '#224c74',
      filter: 'drop-shadow(2px 4px 6px black)',
    },
    text: {
      color: '#fff',
      textAlign: 'center',
      width: '100%',

    },
    text1: {
      top: '17px', fontSize: '2.0em',
      [theme.breakpoints.up('400')]: {
        top: '30px', fontSize: '2.4em',
      }
    },
    text2: {
      top: '53px', fontSize: '1.8em',
      marginTop: '10px',
      [theme.breakpoints.up('400')]: {
        top: '73px', fontSize: '2em',
      }
    },
    text3: {
      top: '198px', fontSize: '1em',
      marginTop: '10px',

      [theme.breakpoints.up('400')]: {
        top: '243px', fontSize: '1em',
      }
    },
    textLink: {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
}));

export default function MaxWidthDialog({
    open,
    handleClose
}) {

  const classes = useStyles();
  const history = useHistory();
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const referralHandler = () => {
        handleClose();
        history.push("/referrals");
  };
  
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.rootD}
      >
        <DialogContent>
          <div className={classes.banner}>
          <p style={{ fontWeight: 600 }} className={classes.text + " " + classes.text1}>Refer a friend</p>
          <p style={{ fontWeight: 400, color: '#eee' }} className={classes.text + " " + classes.text2}>and get</p>
          <img style={{ marginTop: '30px', }} src={baneerOfferRef} />
          <p style={{ fontWeight: 400, color: '#eee' }} className={classes.text + " " + classes.text3}>For more details, <b className={classes.textLink} onClick={referralHandler}>click here</b></p>
          </div>
        </DialogContent>
        <HighlightOffIcon className={classes.customIconCloseChat} onClick={handleClose}/>
      </Dialog>
    </React.Fragment>
  );

}