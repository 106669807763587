import React from 'react';
import "./ourVision.scss";

const OurVision = () => {
    return (
        <section className="about-section-4 vision">
        <div className="container vision">
          <div>
            <h2 className="title">Our Vision</h2>
            <h4 className="par">Our vision is that every space we create <span>is one of a kind</span>,  uniquely tailored for each client</h4>
            <h2 className="believe">We Believe</h2>
            <ul className="list">
              <li>...in the power of patience, hard work and perseverance</li>
              <li>...by using checklists, <span>we</span> never make the same mistakes twice</li>
              <li>...the job we <span>do</span> is a reflection of who we are</li>
              <li>...<span>whatever</span> happens, there is always a solution</li>
              <li>...<span>it takes</span> true character to accept the responsibility for a mistake</li>
              <li>...the customer has the obligation <span>to</span> complain, it is how we improve</li>
              <li>...we need to <span>deliver on</span> time and on budget to have happy customers</li>
              <li>...<span>our</span> business depends on referrals from happy customers</li>
              <li>...a promise made, is a <span>promise</span> kept</li>
              <li>...small details matter. If a customer says “don’t worry about it, we absolutely worry about it.</li>
            </ul>
          </div>
        </div>
      </section>
    );
};

export default OurVision;