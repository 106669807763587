import React from 'react';
import Reviews from '../../Containers/Reviews';

import TestimonialsReviewerTwo from '../../Assets/testimonials_images/pic-24.png';
import TestimonialsReviewerThree from '../../Assets/testimonials_images/4.png';
import TestimonialsReviewerFour from '../../Assets/testimonials_images/13.png';
import TestimonialsReviewerFive from '../../Assets/testimonials_images/pic-14.png';
import TestimonialsReviewerSix from '../../Assets/testimonials_images/pic-04.png';
import TestimonialsReviewerSeven from '../../Assets/testimonials_images/pic-16.png';



const testimonialsItems = [
    {
      text: `Recently completed a basement renovation project with AGM and we are completely satisfied with the project; our basement is now a place we actually want to spend time in. Big thanks to Carm our Project Manager; he kept us updated with what was happening and returned all communications. The workers were great; very respectful and polite. Thanks so much Carm and AGM.`,
      name: 'Joel Briere',
      photo: TestimonialsReviewerTwo,
    },
    {
      text: `AGM team has finished our basement renovation in a timely fashion. Their plumber George discovered and fixed a potential future plumbing issue that was a great thing for us. Overall the trades did a fantastic job. We are very pleased with the results. The work was performed at the agreed budget. The administration was very communicative and responsive throughout the project. We wouldn’t hesitate in hiring AGM renovations for future projects and highly recommend their services!`,
      name: 'Ston Mil',
      photo: TestimonialsReviewerFive,
    },
    {
      text: `Matthew did an excellent job in getting the kitchen completed. Very professional and I love the completed kitchen. Highly recommended to look for Matthew when doing a kitchen Reno with AGM. Loving my kitchen with a smile. -Ashanti Maryanne and Ryan did a excellent job and project was done efficiently and on time Well Done and I am loving bathroom`,
      name: 'Ashanti Britton',
      photo: TestimonialsReviewerThree,
    },
    {
      text: `AGM contractors turned out to be true professionals and were great to work with. The project manager Greg was there for us every step of the way helping us with design and planning. Our project started on time and the crew was great. We are enjoying a nice warm basement with beautiful cabinets and fixtures. We couldn't ask for a better crew or a better job. Greg followed up after completion to check if we were pleased with the work completed and if we needed anything adjusted or taken care of. This was great ! We would recommend AGM to anyone looking for a good company that does really great work!`,
      name: 'Alexander Hardy',
      photo: TestimonialsReviewerSix,
    },
    {
      text: `We hired AGM for our bathroom renovation. The work was completed with minimal disruption of our daily lives at home. we now have a modern bathroom to take pride in. Vadim the renovator is very reliable, professional and pays attention to minute details. We have already recommended AGM to our friends and family. Whoever has seen our new bathroom are very positive about AGM! We are sure AGM will get few projects from our friend circle!`,
      name: 'Iqbal Shaikh',
      photo: TestimonialsReviewerSeven,
    },
    {
      text: `I chose AGM after a recommendation. Was not disappointed after a whole basement renovation. Ricardo was our project manager and was very quick to respond to any questions I had. Anything that needed to be addressed or fixed was done quickly, and with no arguments. Thank you, AGM and thanks for having such professionals as Ricardo making the process quick.`,
      name: 'Philip Zakrzewski',
      photo: TestimonialsReviewerFour,
    }
];

export const ReviewsPage = (props) => {

  return (
    <>
        <Reviews
            loopItems={testimonialsItems}
            title={'AGM Renovations Reviews | AGM Kitchen Renovation | AGM Renovations'}
            description={'Click here to learn how AGM Renovations reviews every kitchen renovation project to guarantee complete customer satisfaction.'}
            keywords={'AGM Renovations reviews'}
        >
          <p style={{ textAlign: "center" }}>
            AGM Renovations reviews its process to simplify the renovation process, making it easy for you to get the kitchen of your dreams right now.
            <br/><br/>
            With our interactive 3D Design Creator and ability to provide on-
            the-spot quotes, we make the journey to your new kitchen convenient, funfun, and most importantly, so easy!
            <br/><br/>
            AGM Renovation reviews our kitchen renovation processes to ensure complete customer satisfaction. It’s why
            we offer a five-year unlimited warranty on every kitchen renovation project. Because AGM Renovations
            reviews the industry, we are so confident that our quotes are the best, that any company offering a lower
            quote in writing will have their price matched immediately.
            <br/><br/>
            Kitchen renovation projects provide a great way to raise the value of your home. AGM Renovation reviews the
            resell market and we know that our renovation projects can significantly raise the value of the homes of our
            customers. In fact, our clients routinely have their homes sell well over the original asking price.
          </p>
        </Reviews>
    </>
  )

}