import React, { Component } from 'react';

import { Link } from "react-router-dom";
import axios from 'axios';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import s_project_1 from '../../Assets/images/s_project_1.jpg';
import s_project_2 from '../../Assets/images/s_project_2.jpg';
import s_project_3 from '../../Assets/images/s_project_3.jpg';
import s_project_4 from '../../Assets/images/s_project_4.jpg';
import s_project_5 from '../../Assets/images/s_project_5.jpg';

import one from '../../Assets/images/ourwork/1.jpg';
import two from '../../Assets/images/ourwork/2.jpg';
import three from '../../Assets/images/ourwork/3.jpg';
import four from '../../Assets/images/ourwork/4.jpg';
import five from '../../Assets/images/ourwork/5.jpg';
import seven from '../../Assets/images/ourwork/7.jpg';
import zeroseven from '../../Assets/images/ourwork/07.jpg';
import c from '../../Assets/images/ourwork/c.jpg';
import smd from '../../Assets/images/ourwork/smd.jpg';

import tilesFrame from '../../Assets/images/tiles_frame.png';
import vanitiesFrame from '../../Assets/images/vanities_frame.png';
import toiletsFrame from '../../Assets/images/toilet_frame.png';
import bathtubFrame from '../../Assets/images/bathtub_frame.png';
import vanityFaucetsFrame from '../../Assets/images/vanity_faucets_frame.png';
import showerFaucetsFrame from '../../Assets/images/shower_faucets_frame.png';
import bathtubFaucetsFrame from '../../Assets/images/bathtub_faucets_frame.png';
import accessoriesFrame from '../../Assets/images/accessories_frame.png';

import { TILES, VANITIES, TOILETS, BATHTUBS, VANITY_FAUCETS, SHOWER_FAUCETS, BATHTUB_FAUCETS, ACCESSORIES } from '../../Common/finishes';

import Finish from './Components/Finish';
import { API_URL } from '../../Constants/default';

import './Finishes.scss';

class Finishes extends Component {
  static propTypes = {
  };

  state = {
    selectedItem: TILES,
    finishesTitle: 'Floor/Wall Tile',
    subCategories: [],
    selecttionType: 'single', // multiple, multi-items
    // Wasnt sure what would be needed for the future API so I am creating state for both options.
    // Can remove anything uneeded here
    selectedFinishesIDs: {
      tiles: null,
      vanities: null,
      toilets: null,
      bathtubs: null,
      vanity_faucets: null,
      shower_faucets: null,
      bathtub_faucets: null,
      accessories: [],
    },
    selectedFinishes: {
      tiles: null,
      vanities: null,
      toilets: null,
      bathtubs: null,
      vanity_faucets: null,
      shower_faucets: null,
      bathtub_faucets: null,
      accessories: [],
    },
    items: {},
  };

  handleFinishTypeClick = (type) => {
    this.setState({
      selectedItem: type,
      subCategories: [],
      availableFinishes: [],
    });

    if (type === TILES) {
      this.setState({
        finishesTitle: 'Floor/Wall Tile'
      });
      this.fetchFinishes(2);
    } else if (type === VANITIES) {
      this.setState({
        finishesTitle: 'Vanities'
      });
      this.fetchFinishes(3);
    } else if (type === TOILETS) {
      this.setState({
        finishesTitle: 'Toilets'
      });
      this.fetchFinishes(1);
    } else if (type === BATHTUBS) {
      this.setState({
        finishesTitle: 'Bathtubs'
      });
      this.fetchFinishes(5);
    } else if (type === VANITY_FAUCETS) {
      this.setState({
        finishesTitle: 'Vanity Faucets'
      });
      this.fetchFinishes(6);
    } else if (type === SHOWER_FAUCETS) {
      this.setState({
        finishesTitle: 'Shower Faucets'
      });
      this.fetchFinishes(7);
    } else if (type === BATHTUB_FAUCETS) {
      this.setState({
        finishesTitle: 'Bathtub Faucets'
      });
      this.fetchFinishes(8);
    } else if (type === ACCESSORIES) {
      this.setState({
        finishesTitle: 'Accessories',
        selecttionType: 'multiple'
      });
      this.fetchFinishes(9);
    }
  }

  handleFinishClick = (category, subCategory, item) => {
    if (this.state.selecttionType === 'single') {
      let { items } = this.state;
      if (items.hasOwnProperty(category.title)) {
        items[category.title][subCategory.title] = item;
      } else {
        items[category.title] = {}
        items[category.title][subCategory.title] = {}
        items[category.title][subCategory.title] = item;
      }
      this.setState({ items });
    } else if (this.state.selecttionType === 'multiple') {
      let { items } = this.state;
      if (items.hasOwnProperty(category.title)) {
        items[category.title] = [...items[category.title], item];
      } else {
        items[category.title] = []
        items[category.title].push(item);
      }
      this.setState({ items });
    }
    // console.log('TEST', group, id);
    // let cat_name = category_name;
    // cat_name = cat_name.split(' ').join('_');
    // cat_name = cat_name.toLowerCase();
    // if (category_id === 9) {
    //   const accessoryIDs = [...this.state.selectedFinishesIDs.accessories];
    //   const accessoryNames = [...this.state.selectedFinishes.accessories];
    //   const index = accessoryIDs.indexOf(id);

    //   if (index !== -1) {
    //     accessoryIDs.splice(index, 1);
    //     accessoryNames.splice(index, 1);
    //     this.setState({
    //       selectedFinishesIDs: {
    //         ...this.state.selectedFinishesIDs,
    //         accessories: accessoryIDs
    //       },
    //       selectedFinishes: {
    //         ...this.state.selectedFinishes,
    //         accessories: accessoryNames
    //       },
    //     });
    //   } else {
    //     this.setState({
    //       selectedFinishesIDs: {
    //         ...this.state.selectedFinishesIDs,
    //         accessories: [...this.state.selectedFinishesIDs.accessories, id]
    //       },
    //       selectedFinishes: {
    //         ...this.state.selectedFinishes,
    //         accessories: [...this.state.selectedFinishes.accessories, finishName]
    //       }
    //     });
    //   }
    // } else {
    //   this.setState({
    //     selectedFinishesIDs: {
    //       ...this.state.selectedFinishesIDs,
    //       [cat_name]: id
    //     },
    //     selectedFinishes: {
    //       ...this.state.selectedFinishes,
    //       [cat_name]: finishName
    //     }
    //   });
    // }
  }

  handleNextStep = async () => {
    if (this.state.selectedItem === TILES) {
      this.handleFinishTypeClick(VANITIES);
    } else if (this.state.selectedItem === VANITIES) {
      this.handleFinishTypeClick(TOILETS);
    } else if (this.state.selectedItem === TOILETS) {
      this.handleFinishTypeClick(BATHTUBS);
    } else if (this.state.selectedItem === BATHTUBS) {
      this.handleFinishTypeClick(VANITY_FAUCETS);
    } else if (this.state.selectedItem === VANITY_FAUCETS) {
      this.handleFinishTypeClick(SHOWER_FAUCETS);
    } else if (this.state.selectedItem === SHOWER_FAUCETS) {
      this.handleFinishTypeClick(BATHTUB_FAUCETS);
    } else if (this.state.selectedItem === BATHTUB_FAUCETS) {
      this.handleFinishTypeClick(ACCESSORIES);
    } else {
      console.log(this.state, 'state');
      const { items } = this.state;
      let body = []
      const customer_id = parseInt(sessionStorage.getItem('customer_id'), 10);
      Object.entries(items).reduce((acc, cur) => {
        if (Array.isArray(cur[1])) {
          body = [...body, ...cur[1].map(c => ({ customer_id, finish_item_id: c.id }))]
        } else {
          body = [...body, ...Object.values(cur[1]).map(c => ({ customer_id, finish_item_id: c.id }))]
        }
      }, [])
      console.log(body)
      const res = await axios.post(`${API_URL}/finish-selections`, {
        finishes: body
      })
      if (res.data.success === true) {
        this.props.history.push('/thank-you');
      }
    }

  }

  componentDidMount() {
    this.fetchFinishes(2);
  }

  fetchFinishes = (category) => {
    const baseURL = 'https://bathroom-api.herokuapp.com';
    let currentComponent = this;

    axios.get(baseURL + '/finish-item-subcategories?related=items,category&filter[category_id]=' + category)
      .then(function (response) {
        console.log('RESPONSE', response);
        if (response && response.data && response.data.results && response.data.results.length > 0) {
          currentComponent.setState({
            subCategories: response.data.results,
          });
        }
      });

    axios.get(baseURL + '/bathroom-finish-items?filter[category_id]=' + category + '&related=category,subCategory')
      .then(function (response) {
        // console.log('RESPONSE', response);
        currentComponent.setState({
          availableFinishes: response.data.results,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }
  checkSelected = (category, subCategory, item) => {
    console.log(category, subCategory, item, this.state)
    const isArray = Array.isArray(this.state.items.hasOwnProperty(category.title) && (this.state.items[category.title]))
    // if (this.state.selecttionType === 'single') {
    //   if (this.state.items.hasOwnProperty(category.title) && this.state.items[category.title][subCategory.title] && this.state.items[category.title][subCategory.title].id === item.id) {
    //     return true;
    //   }
    // } else if (this.state.selecttionType === 'multiple') {
    if (this.state.items.hasOwnProperty(category.title) && (isArray ? this.state.items[category.title].map(i => i.id).includes(item.id) : this.state.items[category.title][subCategory.title] && this.state.items[category.title][subCategory.title].id === item.id)) {
      return true;
    }
    // }
    return false;
  }
  handleCheck = (e) => {
    if (e.target.checked) {
      this.setState({ selecttionType: 'multiple', items: {} });
    } else {
      this.setState({ selecttionType: 'single', items: {} });
    }
  }
  render() {
    // console.log(this.state, 'this.state.subCategories');
    return (
      <>
        <section className="finishes">
          <div className="options-section">
            <div className="container">
              <div className="options">
                <div className={`single-option ${this.state.selectedItem === TILES ? 'active' : null}`} onClick={() => this.handleFinishTypeClick(TILES)}><div className="image-holder"><img src={tilesFrame} alt="Tiles Frame" /></div><h3>Tiles</h3>{this.state.selectedFinishesIDs.tiles ? <i className="far fa-check-circle green" /> : <i className="fas fa-caret-down" />}</div>
                <div className={`single-option ${this.state.selectedItem === VANITIES ? 'active' : null}`} onClick={() => this.handleFinishTypeClick(VANITIES)}><div className="image-holder"><img src={vanitiesFrame} alt="Tiles Frame" /></div><h3>Vanities</h3>{this.state.selectedFinishesIDs.vanities ? <i className="far fa-check-circle green" /> : <i className="fas fa-caret-down" />}</div>
                <div className={`single-option ${this.state.selectedItem === TOILETS ? 'active' : null}`} onClick={() => this.handleFinishTypeClick(TOILETS)}><div className="image-holder"><img src={toiletsFrame} alt="Tiles Frame" /></div><h3>Toilets</h3>{this.state.selectedFinishesIDs.toilets ? <i className="far fa-check-circle green" /> : <i className="fas fa-caret-down" />}</div>
                <div className={`single-option ${this.state.selectedItem === BATHTUBS ? 'active' : null}`} onClick={() => this.handleFinishTypeClick(BATHTUBS)}><div className="image-holder"><img src={bathtubFrame} alt="Tiles Frame" /></div><h3>Bathtubs</h3>{this.state.selectedFinishesIDs.bathtubs ? <i className="far fa-check-circle green" /> : <i className="fas fa-caret-down" />}</div>
                <div className={`single-option ${this.state.selectedItem === VANITY_FAUCETS ? 'active' : null}`} onClick={() => this.handleFinishTypeClick(VANITY_FAUCETS)}><div className="image-holder"><img src={vanityFaucetsFrame} alt="Tiles Frame" /></div><h3>Vanity Faucets</h3>{this.state.selectedFinishesIDs.vanity_faucets ? <i className="far fa-check-circle green" /> : <i className="fas fa-caret-down" />}</div>
                <div className={`single-option ${this.state.selectedItem === SHOWER_FAUCETS ? 'active' : null}`} onClick={() => this.handleFinishTypeClick(SHOWER_FAUCETS)}><div className="image-holder"><img src={showerFaucetsFrame} alt="Tiles Frame" /></div><h3>Shower Faucets</h3>{this.state.selectedFinishesIDs.shower_faucets ? <i className="far fa-check-circle green" /> : <i className="fas fa-caret-down" />}</div>
                <div className={`single-option ${this.state.selectedItem === BATHTUB_FAUCETS ? 'active' : null}`} onClick={() => this.handleFinishTypeClick(BATHTUB_FAUCETS)}><div className="image-holder"><img src={bathtubFaucetsFrame} alt="Tiles Frame" /></div><h3>Bathtub Faucets</h3>{this.state.selectedFinishesIDs.bathtub_faucets ? <i className="far fa-check-circle green" /> : <i className="fas fa-caret-down" />}</div>
                <div className={`single-option ${this.state.selectedItem === ACCESSORIES ? 'active' : null}`} onClick={() => this.handleFinishTypeClick(ACCESSORIES)}><div className="image-holder"><img src={accessoriesFrame} alt="Tiles Frame" /></div><h3>Accessories</h3>{this.state.selectedFinishesIDs.accessories.length ? <i className="far fa-check-circle green" /> : <i className="fas fa-caret-down" />}</div>
              </div>
            </div>
          </div>
        </section>
        <section className="finish-items">
          <div className="container">
            <div className="section-title">
              <h2>Pick Your Finishes</h2>
            </div>

            {this.state.subCategories && this.state.subCategories.length ? (
              this.state.subCategories.map((subCat) => (
                <>
                  <h3 className={subCat.title === 'Floor/Wall' ? 'no-bottom-marg' : null}>{subCat.title}</h3>
                  {subCat.title === 'Floor/Wall' ? (
                    <FormControlLabel
                      control={<Checkbox
                        checked={this.state.selecttionType === 'multiple'}
                        onChange={this.handleCheck}
                      />}
                      label="Allow multiple tile selection"
                    />
                  ) : null}
                  <div className={`finish-items-holder ${subCat.title === 'Floor/Wall' ? 'tiles' : null} ${subCat.title === 'Shower floor Mosaics' ? 'tiles' : null}`}>
                    {(subCat.items || []).map((item) => (
                      <div onClick={() => this.handleFinishClick(subCat.category, subCat, item)} key={item.id}>
                        <Finish
                          title={item.title}
                          // description={item.description}
                          image={item.image}
                          selected={this.checkSelected(subCat.category, subCat, item)}
                        // selected={item.category_id === 9 ? [...this.state.selectedFinishesIDs.accessories].indexOf(item.id) !== -1 : this.state.selectedFinishesIDs[subCat.category.title.split(' ').join('_').toLowerCase()] === item.id}
                        />
                      </div>
                    ))}
                  </div>
                </>
              ))
            ) : (
                <div className="finish-items-holder">
                  {this.state.availableFinishes && this.state.availableFinishes.map((item) => (
                    <div onClick={() => this.handleFinishClick({}, {}, item)} key={item.id}>
                      <Finish
                        title={item.title}
                        // description={item.description}
                        image={item.image}
                        selected={this.checkSelected({}, {}, item)}

                      // selected={item.category_id === 9 ? [...this.state.selectedFinishesIDs.accessories].indexOf(item.id) !== -1 : this.state.selectedFinishesIDs[item.category.title.split(' ').join('_').toLowerCase()] === item.id}
                      />
                    </div>
                  ))}
                </div>
              )}
          </div>
        </section>

        <section className="works-text">
          <div className="container">
            <h2>Your Dream Bathroom is Only a Phone Call Away</h2>
            <p>We’ve helped countless Greater Toronto homeowners improve their bathrooms over the years – are you next? Call 416-477-5429 today to discover how AGM can help turn your old, outdated bathroom into the modern design of your dreams.</p>
          </div>
        </section>

        <section className="works-images">
          <div className="container">
            <ul>
              <li><img src={s_project_1} alt="" /></li>
              <li><img src={s_project_2} alt="" /></li>
              <li><img src={s_project_3} alt="" /></li>
              <li><img src={s_project_4} alt="" /></li>
              <li><img src={s_project_5} alt="" /></li>
            </ul>
          </div>
        </section>

        <div className="floating-next">
          <span><strong>Current Step:</strong> {this.state.finishesTitle}</span>
          <button onClick={this.handleNextStep}>{this.state.selectedItem === ACCESSORIES ? 'Complete' : 'Continue'}</button>
        </div>
      </>
    );
  }
}

export default Finishes;