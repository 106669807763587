import React from 'react';

import { 
    WarrantyAdvanced
} from '../../Containers/Warranty'

export const WarrantyPage = (props) => {
  
  return (
    <>
      <WarrantyAdvanced />
    </>
  );
}