import React, { Component, useState } from "react";
import { Link } from "react-router-dom";

import Stations from "../../Common/Stations";
import StationsUS from "../../Common/StationsUS";
import { SliderText } from "./SliderText";
// import '../../../node_modules/react-modal-video/scss/modal-video.scss';
// import Laptop from "../../Assets/images/laptop_agm-design-tool.png";
// import youtubeLogo from "../../Assets/images/youtube-icon.png";
// import LaptopMobile from "../../Assets/images/laptop_agm-design-tool_mobile.png";
// import blueCheckmark from "../../Assets/images/checkmark-blue.png";
// import whiteLarge from "../../Assets/images/banner-white-background-blob.png";
// import ModalVideo from 'react-modal-video'
import VideoModal from "../../Common/videoModal";
// import ReactPlayer from "react-player";
// import { render } from "@testing-library/react";
import CloseIcon from '@mui/icons-material/Close';
import filesFetcher from '../../Services/filesFetcher';

// export const TopSection = (props) => {
// const tool3dRegions = ['ottawa', 'gta', 'calgary', 'vancouver'];

// const getStations = async (stationsCountry, that) => {

//     const stationsToLoad = {
//       CA:  () => import('../../Common/Stations'),
//       US: () => import('../../Common/StationsUS')
//     }

//     if (!stationsToLoad[stationsCountry]) stationsCountry = 'CA';
//     that.state.countryCode = stationsCountry;
//     console.error('that.state.countryCode', that.state.countryCode);
//     const mod = await stationsToLoad[stationsCountry]();
//     console.error('thmod.defaulte', mod.default);
//     that.Stantions = mod.default;
// }
// laptopVideo: 's0hz5E1E2ZU',
// const videoId = countryCode === 'CA' ? '0_Rr23cnD0Q' : 'TeXeepjTWKA';

const isKitchenApp = process.env.REACT_APP_NAME === 'kitchens';
const isBathroomApp = process.env.REACT_APP_NAME === "bathrooms";

const getOfferData = async (setOffer) => {
  const offers = await filesFetcher.fetchPromoOffer();
  const offerData = offers.find((item) => item.value === (isKitchenApp ? "Kitchen" : isBathroomApp ? "Bathroom" : 'Basements'));

  if (offerData)
    setOffer(offerData.banner);
};

const Banner = () => {
  const [offer, setOffer] = useState(null);
  
  if (!offer) {
    getOfferData(setOffer);
    return null;
  }

  return (
    <div className="promoStamp">
      <button 
        onClick={(e)=> { 
          e.currentTarget.parentElement.style.display = 'none'; 
          e.currentTarget.parentElement.parentElement.classList.add('closedPromo'); }} 
        className="closeBtn"
      >
        <CloseIcon/>
      </button>
      <p className="topPar">
        <span>
          Save {offer.amount} on {isKitchenApp ? 'Kitchen': isBathroomApp ? "Bathroom" : 'Basement'} Renovations
        </span>
      </p>

      <p className="middleOffer desk">
        <strong>No</strong> Money Down <br/>
        <strong>No</strong> Interest <br/>
        <strong>No</strong> Payments for 1 Year*
      </p>
      <p className="middleOffer mobile">
        <strong>No</strong> Money Down, <strong>No</strong> Interest, <strong>No</strong> Payments for 1 Year*
      </p>


          {offer.extra 
                ? <p style={{ textAlign: 'center', paddingTop: '10px' }} className="middleOffer image desk">
                  <strong>{
                    (offer.extra.substring(0, 1) === '$') ? `Take an extra ${offer.extra} OFF your project` : offer.extra
                  }</strong>
                  </p> 
                : null 
              }
          {offer.extra 
                ? <p style={{ textAlign: 'center', paddingTop: '10px' }} className="middleOffer image mobile">
                  <strong>{
                    (offer.extra.substring(0, 1) === '$') ? `Take an extra ${offer.extra} OFF your project` : offer.extra
                  }</strong>
                  </p> 
                : null 
              }
    
          <Link sx={{ color: '#fff', fontWeight: 700 }} to="/get-started?offer=1" className="blue-dark-windows-btn">
            <div style={{ color: '#fff', fontWeight: 700 }} className="button">
              See Offer
            </div>
          </Link>

      <span className="endPart">Offer ends {offer.end}</span>
    </div>
  );
};


export class TopSection extends Component {
  
  constructor (props) {
    super()
    this.state = {
      isOpen: false,
      countryCode: props.location?.countryCode,
    }
    this.videos = {
      laptopVideo: this.state.countryCode === 'CA' ? '0_Rr23cnD0Q' : 'TeXeepjTWKA',
      HGTV: '5kVBooRtR4Q',
    };
    this.openModal = this.openModal.bind(this)
    this.handleHGTVStar = this.handleHGTVStar.bind(this)
    this.Stantions = null
  }

  componentDidUpdate() {
    const countryCode = this.props.location?.countryCode;

    if (countryCode && this.state.countryCode !== countryCode) {
      this.setState({ countryCode });
      this.videos = {
        laptopVideo: countryCode === 'CA' ? '0_Rr23cnD0Q' : 'TeXeepjTWKA',
        HGTV: '5kVBooRtR4Q',
      };
    }
  }

  openModal () {
    this.setState({ isOpen: true, videoId: this.videos.laptopVideo })
  }

  handleHGTVStar () {
    this.setState({ isOpen: true, videoId: this.videos.HGTV })
  }
  

  render(){

    const { title, subtitle, listTitle, listItems, backgroundImage, className, children, noPhoto } = this.props;


    // if(region==='calgary' || region==='vancouver' ){

      // TODO move welcome-text up on < 1100px width
      return (
        <>
          <section 
           className={`home-section ${className ? className : ''}`} 
           style={{ marginTop: '-220px' }}
          //  backgroundPositionY: '0px',
          >
            <div className="banner-cover" />
            <section className="home-banner">
              <div className="welcome-text" >
                <h1>{title}</h1>
                <p className="welcome-sub">{subtitle}</p>
                <h4>{listTitle}</h4>
                <ul>
                  {listItems && listItems.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
                <Link to="/get-started" className="blue-btn desktop-btn">Free Consultation</Link>
              </div>
              { this.state.isOpen && 
                  <VideoModal
                    isOpen={this.state.isOpen}
                    videoId={this.state.videoId}
                    handleClose={ ()=>{ this.setState({ isOpen: false }); } }
                  />
              }

            {/* {noPhoto ? null : (
              <div className="footerInfoBar" style={{ marginRight: '2vw' }}>
                <div className="itemInfo">
                  <b className="border">Ivan A.</b>
                  <span className="border">CEO AGM Renovations</span>
                </div>
                <div onClick={this.handleHGTVStar} className="itemInfo hovered" style={{ paddingLeft: 0, marginLeft: '2vw'}}>
                  <b>Mike Holmes</b>
                  <span  style={{ padding: 0 }} ><strong>HGTV</strong> Star & Contractor</span>
                   <hr/>
                </div>
              </div>
            )} */}

            </section>
            {children}

            <Banner/>

          </section>

          {/* {this.Stantions && <this.Stantions />} */}
          <SliderText showButton={false} />
          {this.state.countryCode === 'CA' 
            ? (<Stations/>)
            : (< StationsUS/>)
          }
        </>
      )

  }
}
export default TopSection;