import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Box';
import VideoPreview from './VideoPreview';

import laptopImg from '../../Assets/images/laptop_agm-design-tool.png';

class ThankYou extends Component {

  constructor(){
    super();
    this.state = {
      authdata: null
    }
  }

  componentDidMount() {


    if (!window.URLSearchParams) {
      (function (w) {
        w.URLSearchParams = w.URLSearchParams || function (searchString) {
          var self = this;
          self.searchString = searchString;
          self.get = function (name) {
            var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(self.searchString);
            if (results == null) {
              return null;
            } else {
              return decodeURI(results[1]) || 0;
            }
          };
        }
      })(window)
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
 
    if (params && params.auth) {
      if (params.auth !== 'null') {
        localStorage.setItem('regUserData', params.auth);
        // this.setState({ authdata: JSON.parse(params.auth) });
      }
      removeParam('auth');
    } else if (localStorage.getItem('regUserData')) {
      // this.setState({ authdata: JSON.parse(localStorage.authdata) });
    }

    function removeParam(paramName) {
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.delete(paramName);

      if (window.history.replaceState) {
        let searchString = searchParams.toString().length > 0 ? '?' + searchParams.toString() : '';
        let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + searchString + window.location.hash;
        window.history.replaceState(null, '', newUrl);
      }
    }
  }

  render() {
    const is3dDesigner = process.env.REACT_APP_NAME === 'bathrooms';

    return (
      <>
        <Helmet>
           <meta name="robots" content="noindex"/>
        </Helmet>

        <div style={{
          marginTop: '64px',
          marginBottom: '120px'
        }}>
        <div style={{
              maxWidth: '600px',
              width: '100%',
              display: 'block',
              boxSizing: 'border-box',
              marginLeft: 'auto',
              marginRight: 'auto'
        }} >
          <div className="text-center">
            <h1>Thank You</h1>
            <p>We will get back to you soon!</p>
          </div>
        </div>

        { is3dDesigner && 
            <div style={{ paddingTop: '50px' }}>
              <br/>
                <div style={{ marginTop: '-20px', position: 'relative', top: '-40px' }} className="text-center">
                  <h5 style={{color: '#1d3c5a'}}>Get started and configure your bathroom today</h5>
                </div>

                <VideoPreview/>
          
                <a style={{ margin: '0 auto' }} class="blue-btn desktop-btn" href="/design">Design your Bathroom</a>
            </div>
        }
      </div>
      </>
    );
  }

}

export default ThankYou;

        //   <img src={laptopImg} alt=""/>
