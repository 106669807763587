import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
// import { Link } from "react-router-dom";
import filesFetcher from "../../Services/filesFetcher";

import LeftBtn from "../../Assets/images/latestwork-left-btn.png";
import RightBtn from "../../Assets/images/latestwork-right-btn.png";

import "./WindowsOurLatestWork.scss";

const sliderSettings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  centerMode: true,
  infinite: true,
  centerPadding: "5px",
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};



export const WindowsOurListing = ({
  title,
  hideButton,
  subtitle,
  noTitle,
  noButton,
  handler,
  children
}) => {

  const [listingImages, setListingImages] = useState(null);

  useEffect(async () => {
    const listingImagesLoaded = await filesFetcher.fetchListingImages();
    const listingImages = listingImagesLoaded.map( item => {
			return "https://agm-landing.s3.us-east-2.amazonaws.com/websites/listingImages/" + item.url;
		});
    setListingImages(listingImages);
  }, []);


  const additionalSliderSettings = {
    initialSlide: 0,
    autoplay: true,
    focusOnSelect: true,
  };
  const sliderRef = useRef();
  const nextSlide = () => {
    sliderRef.current.slickNext();
  };
  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <section className="ourLatestWork">

      <div className="sliderContainer">
        <div className="arrowLeft" style={{ cursor: 'pointer' }} onClick={prevSlide}>
          <img src={LeftBtn} />
        </div>
        <div className="testimonial-slider">
          <Slider
            {...sliderSettings}
            {...additionalSliderSettings}
            ref={sliderRef}
          >
            {listingImages ? listingImages.map((item, index, items) => (
              <div className="box" key={index} onClick={()=>{ 
                handler.updateState({ contentImage: items[index] }); 
                handler.index = index;
                handler.items = items;
              }}>
                <img style={{borderRadius: '0px'}} src={item} />
              </div>
            )) : null}
          </Slider>
        </div>
        <div className="arrowRight" style={{ cursor: 'pointer' }} onClick={nextSlide}>
          <img src={RightBtn} />
        </div>
      </div>

      {children}
      
    </section>
  );
};
