export const TILES = [
  {
    id: 1,
    group: 'tile',
    title: 'Tile 1',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile1.jpg'
  },
  {
    id: 2,
    group: 'tile',
    title: 'Tile 2',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile2.png'
  },
  {
    id: 3,
    group: 'tile',
    title: 'Tile 3',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile3.png'
  },
  {
    id: 4,
    group: 'tile',
    title: 'Tile 4',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile4.png'
  },
  {
    id: 5,
    group: 'tile',
    title: 'Tile 4',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile4.png'
  },
  {
    id: 6,
    group: 'tile',
    title: 'Tile 4',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile4.png'
  }
];

export const VANITIES = [
  {
    id: 1,
    group: 'vanities',
    title: 'Tile 1',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile1.jpg'
  },
  {
    id: 2,
    group: 'vanities',
    title: 'Tile 2',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile2.png'
  }
];
export const TOILETS = [
  {
    id: 1,
    group: 'toilet',
    title: 'Tile 4',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile4.png'
  },
  {
    id: 2,
    group: 'toilet',
    title: 'Tile 4',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile3.png'
  },
  {
    id: 3,
    group: 'toilet',
    title: 'Tile 4',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile2.png'
  }
];
export const BATHTUBS = [
  {
    id: 1,
    group: 'bathtub',
    title: 'Tile 4',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile4.png'
  },
  {
    id: 2,
    group: 'bathtub',
    title: 'Tile 4',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile1.jpg'
  },
  {
    id: 3,
    group: 'bathtub',
    title: 'Tile 4',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile4.png'
  },
  {
    id: 4,
    group: 'bathtub',
    title: 'Tile 4',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile2.png'
  },
  {
    id: 5,
    group: 'bathtub',
    title: 'Tile 4',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile4.png'
  },
  {
    id: 6,
    group: 'bathtub',
    title: 'Tile 4',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile3.png'
  }
];
export const VANITY_FAUCETS = [
  {
    id: 1,
    group: 'vanity_faucet',
    title: 'Tile 4',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile1.jpg'
  },
  {
    id: 2,
    group: 'vanity_faucet',
    title: 'Tile 4',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile3.png'
  },
  {
    id: 3,
    group: 'vanity_faucet',
    title: 'Tile 4',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile4.png'
  },
  {
    id: 4,
    group: 'vanity_faucet',
    title: 'Tile 4',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile2.png'
  }
];
export const SHOWER_FAUCETS = [
  {
    id: 1,
    group: 'shower_faucet',
    title: 'Tile 4',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile4.png'
  },
  {
    id: 2,
    group: 'shower_faucet',
    title: 'Tile 4',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile2.png'
  }
];
export const BATHTUB_FAUCETS = [
  {
    id: 1,
    title: 'Tile 4',
    group: 'bathtub_faucet',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile1.jpg'
  },
  {
    id: 2,
    title: 'Tile 4',
    group: 'bathtub_faucet',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile3.png'
  },
  {
    id: 3,
    title: 'Tile 4',
    group: 'bathtub_faucet',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile4.png'
  },
  {
    id: 4,
    title: 'Tile 4',
    group: 'bathtub_faucet',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile2.png'
  },
  {
    id: 5,
    title: 'Tile 4',
    group: 'bathtub_faucet',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile4.png'
  },
  {
    id: 6,
    title: 'Tile 4',
    group: 'bathtub_faucet',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile3.png'
  },
];
export const ACCESSORIES = [
  {
    id: 1,
    title: 'Tile 4',
    group: 'accessories',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile2.png'
  },
  {
    id: 2,
    title: 'Tile 4',
    group: 'accessories',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile4.png'
  },
  {
    id: 3,
    title: 'Tile 4',
    group: 'accessories',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile3.png'
  },
  {
    id: 4,
    title: 'Tile 4',
    group: 'accessories',
    description: 'Some description here',
    image: 'https://agmwebsiteassets.s3.us-east-2.amazonaws.com/finishes/tiles/tile4.png'
  }
];